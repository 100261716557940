import React, { useEffect, useState, useRef } from "react";
import styled from "@emotion/styled";
import { ClearedStyleInput, ClearedStyleTextArea } from "@modules/Components";
import "../../styles/formField.scss";

const InputContainer = styled.div`
  height: ${props => props.inputHeight};
  border-radius: 5px;
  background-color: #ffffff;
  position: relative;
  border: ${props => props.setBorder};
  box-shadow: ${props =>
    props.errorState
      ? "0 20px 70px 0 rgba(31, 32, 33, 0.05)"
      : "0 20px 70px 0 rgba(31, 32, 33, 0.12)"};
  align-items: center;
  display: flex;
`;

const Input = styled(ClearedStyleInput)`
  width: 100%;
  border: 0;
  height: 100%;
  font-size: 16px;
  color: #1f2021;
  padding-left: ${props => props.paddingLeft};
  border-radius: 5px;

  &::-webkit-input-placeholder {
    color: rgba(31, 32, 33, 0.65);
  }
  &:-ms-input-placeholder {
    color: rgba(31, 32, 33, 0.65);
  }
  &::placeholder {
    color: rgba(31, 32, 33, 0.65);
  }

  @media (max-width: 500px) {
    padding-left: ${props => props.mobilePaddingLeft};
  }
  @media (max-width: 375px) {
    padding-left: 20px;
  }
`;

const TextArea = styled(ClearedStyleTextArea)`
  width: 100%;
  border: 0;
  height: 100%;
  font-size: 16px;
  color: #1f2021;
  padding-left: ${props => props.paddingLeft};
  border-radius: 5px;
  padding-top: 25px;
  padding-right: 30px;

  &::-webkit-input-placeholder {
    color: rgba(31, 32, 33, 0.65);
  }
  &:-ms-input-placeholder {
    color: rgba(31, 32, 33, 0.65);
  }
  &::placeholder {
    color: rgba(31, 32, 33, 0.65);
  }

  @media (max-width: 500px) {
    padding-left: ${props => props.mobilePaddingLeft};
  }
  @media (max-width: 375px) {
    padding-left: 20px;
  }
`;

const Absolutecontainer = styled.div`
  top: 6px;
  position: absolute;
  font-size: 10px;
  font-weight: 900;
  letter-spacing: 1px;
  left: 30px;
  text-transform: uppercase;

  @media (max-width: 530px) {
    font-size: 9px;
  }
  @media (max-width: 500px) {
    left: ${props => props.tabletLeft};
  }
  @media (max-width: 465px) {
    font-size: 8px;
  }
  @media (max-width: 375px) {
    left: 20px;
  }
`;

const AbsoluteIconContainer = styled.div`
  position: absolute;
  z-index: 2;
`;

const AbsoluteHoverContainer = styled.div`
  position: absolute;
  bottom: 41px;
  right: -81px;
  z-index: 1;

  @media (max-width: 1100px) {
    width: 250px;
    right: -50px;
  }
  @media (max-width: 500px) {
    width: 200px;
    right: -33px;
  }
  @media (max-width: 375px) {
    width: 170px;
    right: -24px;
  }
`;

const ErrorContainer = styled(Absolutecontainer)`
  color: #ee5050;
`;

const Title = styled(Absolutecontainer)`
  color: rgba(31, 32, 33, 0.5);
`;

const HoverImage = styled.img`
  @media (max-width: 1100px) {
    width: 100%;
  }
`;

const IconImage = styled.img`
  width: 14px;
  height: 14px;
`;

const RenderFormFieldNew = ({
  input,
  type,
  placeholder,
  autoComplete,
  meta: { error, touched, invalid, pristine },
  handleZip,
  valuesFromZip,
  addIcon,
  hoverImage,
  hoverAction,
  onChangeAction,
  formChanged = f => f,
  imageContainerStyles,
  paddingLeft,
  dynamicClass,
  startFocused,
  keyDownAction,
  handlePaste,
  inputHeight,
  inputType,
  topPlaceholder = null,
  setOpacity
}) => {
  const [openHoverImage, changeOpenHoverImage] = useState("display-none");
  const [isFocused, setIsFocused] = useState(false);
  const [focusedSet, setFocusedSet] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    if (startFocused && !focusedSet) {
      inputRef.current.focus();
      setFocusedSet(true);
    }
  }, [inputRef]);

  useEffect(() => {
    if (input.name.includes("zip") && !pristine && !invalid) {
      handleZip(input.value);
    }
  }, [input.value]);

  useEffect(() => {
    if (valuesFromZip) {
      input.onChange(valuesFromZip);
    }
  }, [valuesFromZip]);

  const setFocus = e => {
    input.onFocus(e);
    setIsFocused(true);
  };

  const setBlur = e => {
    input.onBlur(e);
    setIsFocused(false);
  };

  return (
    <InputContainer
      inputHeight={inputHeight ? inputHeight : "72px"}
      className={dynamicClass}
      errorState={touched && error}
      addIcon={addIcon}
      setBorder={
        touched && error
          ? "solid 1px rgba(238, 80, 80, 0.48)"
          : isFocused
          ? "solid 2px #1f2021"
          : ""
      }
    >
      {inputType === "textArea" ? (
        <TextArea
          ref={inputRef}
          paddingLeft={paddingLeft ? paddingLeft : "30px"}
          mobilePaddingLeft={paddingLeft ? paddingLeft : "10px"}
          {...input}
          onFocus={setFocus}
          onBlur={setBlur}
          onKeyDown={e => {
            if (keyDownAction) {
              keyDownAction(e);
            }
          }}
          onPaste={e => {
            if (handlePaste) {
              handlePaste(e);
            }
          }}
          onChange={e => {
            formChanged();
            input.onChange(e);
            if (onChangeAction) {
              onChangeAction();
            }
          }}
          className={`orange-caret render-form-field-input`}
          type={type}
          placeholder={placeholder}
        />
      ) : (
        <Input
          ref={inputRef}
          paddingLeft={paddingLeft ? paddingLeft : "30px"}
          mobilePaddingLeft={paddingLeft ? paddingLeft : "10px"}
          {...input}
          onFocus={setFocus}
          onBlur={setBlur}
          onKeyDown={e => {
            if (keyDownAction) {
              keyDownAction(e);
            }
          }}
          onPaste={e => {
            if (handlePaste) {
              handlePaste(e);
            }
          }}
          onChange={e => {
            formChanged();
            input.onChange(e);
            if (onChangeAction) {
              onChangeAction();
            }
          }}
          className={`orange-caret render-form-field-input ${
            setOpacity && isFocused ? "" : "lower-opacity "
          }`}
          type={type}
          placeholder={placeholder}
          autoComplete={autoComplete}
        />
      )}

      {hoverImage && (
        <AbsoluteHoverContainer>
          <HoverImage
            src={hoverImage}
            className={`${openHoverImage} animated `}
            alt=""
          />
        </AbsoluteHoverContainer>
      )}
      {addIcon && (
        <AbsoluteIconContainer
          onMouseEnter={() => {
            if (hoverImage) {
              changeOpenHoverImage("fadeIn");
            }
            if (hoverAction) {
              hoverAction("enter");
            }
          }}
          onMouseLeave={() => {
            if (hoverImage) {
              changeOpenHoverImage("display-none");
            }
            if (hoverAction) {
              hoverAction("leave");
            }
          }}
          style={imageContainerStyles}
          className={`${setOpacity && isFocused ? "" : "lower-opacity "}`}
        >
          <IconImage src={addIcon} alt="icon" />
        </AbsoluteIconContainer>
      )}
      <ErrorContainer
        tabletLeft={paddingLeft ? paddingLeft : 10}
        className={`input-header`}
      >
        {touched && error && <div>{error}</div>}
      </ErrorContainer>
      <Title
        tabletLeft={paddingLeft ? paddingLeft : 15}
        className={`input-header`}
      >
        {!error && input.value && <div>{topPlaceholder || placeholder}</div>}
      </Title>
    </InputContainer>
  );
};

export default RenderFormFieldNew;
